import { differenceInMinutes } from "date-fns";

import * as cssHiddenClasses from "../entryes/panel/MUI/_Theme.scss"

export const cssHidden = cssHiddenClasses;



export function parseMinutes(value: number) {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return {
    hours, minutes
  }
}

const httpsRegex = /^(http|https):\/\//

export function isLink(link: string) {
  return httpsRegex.test(link)
}

export const extractCourseId = () => {
  const matches = /course_id=(\d*)/.exec(document.location.search);
  return matches ? matches[1] : null;
};

export const toISODate = (date: Date) => {
  return date.toISOString().replace(/T.+/g, "");
}

export const getDateHMDiff = (startDate: any, endDate: any) => {
  const dateInMinutes = differenceInMinutes(startDate, endDate) || 0;
  const hours = Math.floor(Math.abs(dateInMinutes / 60));
  const minutes = Math.abs(dateInMinutes % 60);

  return { minutes, hours };
};

export const upperFirstCase = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const textRichToPlain = (text: string) => {
  return text
    ? text
      .replace(/^\r\n|\r|\n$/gm, "")
      .replace(/<\/?[^>]+(>|)/g, "")
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
    : "";
};

export const textPlainToRich = (text: string) => {
  return text
    ? "<p>" +
    text
      .replace("/&/g", "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\r?\n|\r/g, "&nbsp;</p>\r\n<p>") +
    "</p>"
    : "";
};

const shortNumber = (data: number) => {
  return Math.max(0, 3 - String(Math.floor(data)).length);
};

const translator: any = (window as any).$.translator,
  locales: any = {
    filesizes: [
      translator.translate("Б"),
      translator.translate("Кб"),
      translator.translate("Мб"),
      translator.translate("Гб"),
    ],
  };

export const filesizeHumanize = (
  filesize: number,
  noFloat: boolean = false
) => {
  if (filesize <= 0) {
    return "0 " + locales.filesizes[0];
  } else if (filesize < 1024) {
    return filesize + " " + locales.filesizes[0];
  } else if (filesize > 1024 && filesize < 1048576) {
    const num = filesize / 1024;
    return (
      num.toFixed(noFloat ? 0 : shortNumber(num)) + " " + locales.filesizes[1]
    );
  } else if (filesize > 1048576 && filesize < 1073741824) {
    const num = filesize / 1048576;
    return (
      num.toFixed(noFloat ? 0 : shortNumber(num)) + " " + locales.filesizes[2]
    );
  } else {
    const num = filesize / 1073741824;
    return (
      num.toFixed(noFloat ? 0 : shortNumber(num)) + " " + locales.filesizes[3]
    );
  }
};

export const numberMorfform = (
  data: number,
  num1: string,
  num2: string,
  num3: string
) => {
  const num = Math.abs(data);
  if (num % 100 > 4 && num % 100 < 15) {
    return num3;
  } else if (num % 10 > 1 && num % 10 < 5) {
    return num2;
  } else if (num % 10 == 1) {
    return num1;
  } else {
    return num3;
  }
};

export const _viewport = () => {
  let w: any = window,
    a = "inner";
  if (!("innerWidth" in window)) {
    a = "client";
    w = document.documentElement || document.body;
  }
  return {
    width: w[a + "Width"],
    height: w[a + "Height"],
  };
};

export const pluralMorfForm = (data: number, form1: string, form2: string) => {
  if (Math.abs(data) > 1) {
    return form2;
  }
  return form1;
};

export const capitlalize = (word: string) => {
  return word[0].toUpperCase() + word.substring(1);
};

export const HTML2React = (text: string) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(
    `<!doctype html><body>${text}`,
    "text/html"
  ).body.textContent;
  return decodedString;
};

export const tl = (text: string) =>
  (window as any).$.translator.translate(text);

export const plural = (text: string, value: any) => (window as any).$.translator.plural(text, value)

export const copyToClipboard = async (text: string, callback?: () => void, nodeId?: string) => {
  // Permission API автоматически дает доступ на clipboard-write в активной вкладке,
  // поэтому здесь его использовать не нужно

  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      callback && callback();
    } catch (error) {
      console.log('Не удалось скопировать:', error);
    }
  } else {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    if (callback) {
      callback();
    }
  }
};
export const typeCastToIFilesList = (files: any) => {
  if (Array.isArray(files)) {
    const fileList = files.map((file) => {
      return typeCastToIFile(file);
    });
    return fileList;
  } else return typeCastToIFile(files);
};

export const typeCastToIFile = (file: any) => {
  return {
    id: file.id,
    category: file.category,
    name: getName(file.file),
    extension: getExtension(file.file),
    size: file.size,
    attachedCode: file.code,
    srcUrl: file.src,
    icon: file.icon,
  };
};

export const getName = (file: string) => {
  return file.split(".").shift();
};

export const getExtension = (file: string) => {
  var ext = file.split(".").pop();
  return ext ? "." + ext : "";
};

export const beautyNumbers = (
  num: string | number,
  separator: string = "."
) => {
  const arr = num.toString().split(separator);
  return (
    arr[0].replace(/(\d)(?=(?:\d{3})+\b)/gm, "$& ") +
    (arr[1] ? "." + arr[1] : "")
  );
};

export const getFileCodes = (files: any) => {
  let codes = "";
  files.map((file: any, i: number) => {
    codes += file.attachedCode;
    if (i !== files.length - 1) {
      codes += "\n";
    }
  });
  return codes;
};

export const emailValidation = (email: string) => {
  return /^[^а-яё]+@[^а-яё]+\.[^а-яё]+$/i.test(email) && !email.includes("xn--");
};

export const phoneValidation = (phone: string) => {
  return /^[\+]?\d{2,}?[(]?\d{2,}[)]?[-\s\.]?\d{2,}?[-\s\.]?\d{2,}[-\s\.]?\d{0,9}$/im.test(
    phone
  );
};

export const hextorgba = (color: string, opacity: number) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
    c = color.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  return color;
};

const getRgb = (color: string | number[]) => {
  let rgb;
  if (typeof color === "string") {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    rgb = result
      ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
      : [0, 0, 0];
  } else {
    rgb = color;
  }
  return rgb
}

export const toHSV = (data: string | number[]) => {
  const [r, g, b] = getRgb(data);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const d = max - min;
  let h;
  const s = max === 0 ? 0 : d / max;
  const v = max / 255;

  switch (max) {
    case min:
      h = 0;
      break;
    case r:
      h = g - b + d * (g < b ? 6 : 0);
      h /= 6 * d;
      break;
    case g:
      h = b - r + d * 2;
      h /= 6 * d;
      break;
    case b:
      h = r - g + d * 4;
      h /= 6 * d;
      break;
  }

  return [h, s, v];
};

export const fromHSV = (data: number[]) => {
  const [h, s, v] = data;
  let r, g, b, i, f, p, q, t;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      (r = v), (g = t), (b = p);
      break;
    case 1:
      (r = q), (g = v), (b = p);
      break;
    case 2:
      (r = p), (g = v), (b = t);
      break;
    case 3:
      (r = p), (g = q), (b = v);
      break;
    case 4:
      (r = t), (g = p), (b = v);
      break;
    case 5:
      (r = v), (g = p), (b = q);
      break;
  }

  return (
    `#` +
    `0${Math.round(r * 255).toString(16)}`.slice(-2) +
    `0${Math.round(g * 255).toString(16)}`.slice(-2) +
    `0${Math.round(b * 255).toString(16)}`.slice(-2)
  );
};

export const rgba = (color: string, opacity: number = 1) => {
  const [r, g, b] = getRgb(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
}

export const fromRgba = (color: string) => {
  const arr = color.match(/(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,?\s*([\d\.]+)?\s*)/);
  const r = +arr[2];
  const g = +arr[3];
  const b = +arr[4];
  const opacity = arr[5] ? +arr[5] * 100 : 100;
  const hex = `#` +
    `0${r.toString(16)}`.slice(-2) +
    `0${g.toString(16)}`.slice(-2) +
    `0${b.toString(16)}`.slice(-2)
  return {
    hex: hex,
    opacity: opacity
  };
}

export type ModifyType<T, R> = Omit<T, keyof R> & R;

export const scrollTo = (top: number = 0, speed: number = 500) => {
  const initialY = document.body.scrollTop;
  const y = initialY + top;
  const baseY = (initialY + y) * 0.5;
  const difference = initialY - baseY;
  const startTime = performance.now();
  function step() {
    var normalizedTime = (performance.now() - startTime) / speed;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
};

export const replaceFileCode = (draft: string, courseId: number) => {
  const matches = [];
  let found: Array<any>;
  const reg = /\[FILESTORAGE_(IMG|AUDIO|VIDEO|DOC)_(\d+)(_.*?)?\]/gi;
  while ((found = reg.exec(draft))) {
    matches.push({
      subject: found[0],
      type: found[1],
      file_id: found[2],
      file_name: found[3].substr(1),
    });
  }

  matches.map((match: any) => {
    let code: string;
    switch (match.type) {
      case "IMG":
        code = `<img src="/panel/file/c${courseId}/f${match.file_id}/1"/>`;
        break;
      case "VIDEO":
        code = `<div class='js-video'><div class='js-video_container' style='min-height:250px; width:100%; max-height: 100vh;'><video src="/panel/file/c${courseId
          }/f${match.file_id}/1" width="100%" height="auto" controls="cogetVWidthntrols" controlsList="nodownload" class="js-player fs-video" preload="metadata" data-video-width="1280" data-video-height="720" playsinline webkit-playsinline style='min-height:250px; width:100%;'>${tl(
            "Плеер не поддерживается."
          )}</video></div></div>`;
        break;
      case "AUDIO":
        code = `<audio src="/panel/file/c${courseId}/f${match.file_id
          }/1" controls="controls" class="js-player fs-audio" preload="metadata" width="100%" style="display: none;">${tl(
            "Плеер не поддерживается."
          )}</audio>`;
        break;
      default:
        code = `<a href="/panel/file/c${courseId}/f${match.file_id}/1" target="_blank">${match.file_name}</a>`;
    }
    draft = draft.replace(match.subject, code);
  });

  return draft;
};

export const prepareWasOnline = (wasOnline: string, isOnline?: boolean) => {
  if (isOnline) return ["Online"];
  if (!wasOnline || wasOnline === "") return [tl("Не заходил")];
  let date = new Date();
  date.setDate(date.getDate() - 1);
  const yesterday = date.toLocaleDateString();
  const lastActivity = new Date(
    wasOnline.replace(" ", "T")
  ).toLocaleDateString();
  const today = new Date().toLocaleDateString();
  if (lastActivity === today) {
    return [tl("Сегодня")];
  }
  if (lastActivity === yesterday) {
    return [tl("Вчера")];
  }
  return _viewport().width > 768
    ? [tl("был(а)"), lastActivity]
    : [lastActivity];
};

export const prepareLastActivity = (wasOnline: string, isOnline?: boolean) => {
  if (isOnline) return ["Online"];
  if (!wasOnline || wasOnline === "") return [tl("Не заходил")];
  let date = new Date();
  date.setDate(date.getDate() - 1);
  const yesterday = date.toLocaleDateString();
  const lastActivity = new Date(
    wasOnline.replace(" ", "T")
  ).toLocaleDateString();
  const today = new Date().toLocaleDateString();
  if (lastActivity === today) {
    return tl("Сегодня");
  }
  if (lastActivity === yesterday) {
    return tl("Вчера");
  }
  return tl("В сети " + lastActivity);
};
export const textShadow = (range: number, color: string) => {
  let result = "";
  let x = range;
  while (x >= -range) {
    let y = range;
    while (y >= -range) {
      if (
        (x === range && y === range) ||
        (x === range && y === -range) ||
        (x === -range && y === range) ||
        (x === -range && y === -range)
      ) {
      } else {
        if (result !== "") {
          result += ", ";
        }
        result += `${x}px ${y}px 1px ${color}`;
      }
      y--;
    }
    x--;
  }
  return result;
};

export function deepMerge<T>(...objects: T[]): T {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  function deepMergeInner(target: any, source: any) {
    Object.keys(source).forEach((key: string) => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = deepMergeInner(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }

  if (objects.length < 2) {
    throw new Error('deepMerge: this function expects at least 2 objects to be provided');
  }

  if (objects.some(object => !isObject(object))) {
    throw new Error('deepMerge: all values should be of type "object"');
  }

  const target = objects.shift();
  let source: T;

  while (source = objects.shift()) {
    deepMergeInner(target, source);
  }

  return target;
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
export const resizedataURL = async (imageSrc: string, scale: number) => {
  const image: any = await createImage(imageSrc);
  const w = image.width * scale;
  const h = image.height * scale;
  return new Promise(async function (resolve, reject) {

    // We create an image to receive the Data URI
    var img = document.createElement('img');

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      // We set the dimensions at the wanted size.
      canvas.width = w;
      canvas.height = h;

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(img, 0, 0, w, h);

      var dataURI = canvas.toDataURL();

      // This is the return of the Promise
      resolve(dataURI);
    };

    // We put the Data URI in the image's src attribute
    img.src = imageSrc;

  })
}// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return <File>theBlob;
}


export const base64toFile = (b64Data: any, name: string, contentType: string, sliceSize = 512): File => {
  const byteCharacters = atob(b64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let blob: any = new Blob(byteArrays, { type: contentType });
  blob.lastModifiedDate = new Date();
  blob.name = name;
  return (blob as File);
}